<template>
    <div
        class="policy-wrap"
        v-loading="loading"
    >
        <!-- 匹配问题 -->
        <el-form
            v-if="!isShowRes"
            :model="formData"
            :rules="rules"
            ref="ruleForm"
            class="demo-ruleForm"
            label-position="top"
        >
            <el-form-item
                v-for="(item,index) in showFrom"
                :key="item.id"
                :label="index+1+'、'+item.name"
                :prop="item.id"
            >
                <FormRadioGroup
                    v-model="formData[item.id]"
                    :options="item.children||[]"
                    valueKey="name"
                    labelKey="name"
                ></FormRadioGroup>
            </el-form-item>
            <el-form-item>
                <el-button
                    type="primary"
                    @click="submitForm('ruleForm')"
                >开始匹配</el-button>
                <el-button @click="resetForm('ruleForm')">重置</el-button>
            </el-form-item>
        </el-form>
        <!-- 政策匹配结果 -->
        <div
            v-else
            class="flex-column policy-res"
        >
            <header class="flex-column policy-res__header">
                <div class="title">所选政策/单位</div>
                <div class="flex-row-between content">
                    <div class="content-item">{{policyName}}</div>
                    <div
                        class="flex-1"
                        style="text-align: center; color: red;"
                    >VS</div>
                    <div class="content-item">{{cpu_enterprise}}</div>
                </div>
            </header>
            <!-- 政策匹配-内容 -->
            <div class="flex-column flex-start policy-res__content">
                <div
                    class="flex-column flex-center item"
                    v-for="(item,index) in showFrom"
                    :key="index"
                >
                    <div class="item-title">{{ item.name }}</div>
                    <div class="flex-column item-content">
                        <div class="flex-row flex-around item-content__el">
                            <div class="flex-1 el-left">{{`答：${formData[item.id]}`}}</div>
                            <i
                                class="flex-1 el-right"
                                :class="formData[item.id]===item.correct ? 'el-icon-circle-check color__blue':'el-icon-error color__red'"
                            ></i>
                        </div>
                        <div
                            v-if="formData[item.id]!==item.correct"
                            class="pointer item-content__btn"
                            @click="toAdvisory()"
                        >在线咨询</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { FormRadioGroup } from '@/components/form/index.js'
import $tjCommonDialog from '@/components/tj-common-dialog/index.js'

export default {
    name: '',
    components: {
        FormRadioGroup,
    },
    filters: {},
    props: {
        zcid: {
            type: String,
            default: ''
        },
        // 政策标题
        policyName: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            isShowRes: false,           // 控制 问答 跟 匹配结果
            loading: false,              // 控制加载动画
            showFrom: [     // 匹配参数
                // {
                //     type: '1',      //   0: 单选 1：多选
                //     label: '活动性质',      // 选项标题
                //     id: '34243',           // 选择标题id 
                //     str: 'type',                // 该字段的字符串名, 也可以用做 rules
                //     chilren: [
                //         {
                //             label: '美食',   // 子项名称
                //             id: '231123',    // 子项id
                //         },
                //         {
                //             label: '地推活动',   // 子项名称
                //             id: '12312',    // 子项id
                //         },
                //         {
                //             label: '线下主题活动',   // 子项名称
                //             id: '2312132',    // 子项id
                //         },
                //         {
                //             label: '单纯品牌曝光',   // 子项名称
                //             id: '234353423',    // 子项id
                //         },
                //     ]
                // },
            ],
            formData: {}, // 表单数据
            rules: {}, // 表单校验
            /* 政策咨询结果 */
            zczxList: [
                {
                    label: '活动性质',      // 选项标题
                    id: '34243',           // 选择标题id 
                    isZx: true,            // 是否咨询
                    chilren: [
                        {
                            label: '美食',   // 子项名称
                            id: '231123',    // 子项id
                            status: '0',     // 0: 是匹配失败 1：匹配成功
                        },
                        {
                            label: '地推活动',   // 子项名称
                            id: '12312',    // 子项id
                            status: '0',     // 0: 是匹配失败 1：匹配成功
                        },
                    ]
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            userInfo: "getUserInfo",
            token: "getToken",
        }),
        cpu_enterprise() {
            if (!this.userInfo) return "";
            return this.userInfo?.enterpriseInfo?.enterprise || "";
        },
    },
    methods: {
        /**
         * @description: 匹配按钮
         * @param {*} formName
         * @return {*}
         */
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // 加载动画
                    this.loading = true;
                    // 开始请求
                    this.getPolicyRes()
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        /**
         * @description: 重置
         * @param {*} formName
         * @return {*}
         */
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        /**
         * @description: 获取匹配结果
         * @param {*}
         * @return {*}
         */
        getPolicyRes() {
            // 请求结束，切换成匹配结果
            setTimeout(() => {
                this.loading = false
                this.isShowRes = true;
            }, 1000)
        },
        /**
         * @description: 前往政策咨询页面
         * @param {Object} policy 政策
         */
        toAdvisory(policy) {
            $tjCommonDialog.init({
                width: '400px',
                showClose: false,
                contentComponent: () => import('../sf-policy-library/ExpertAdvisory'),
            })
        },
        getMatchInfo() {
            let url = '/dev-api/sqPolicy/problem/treelist'
            // let url = '/dev-api/sqPolicy/problem/list'
            let params = {
                serviceCode: this.zcid,
            }
            this.$httpApi.get(url, params).then(res => {
                // console.log('getMatchInfo:', res)
                if (res.code == 200 && !!res.rows.length) {
                    // this.showFrom = res.rows
                    for (let i = 0; i < res.rows.length; i++) {
                        // 表单添加响应属性
                        this.$set(this.formData, res.rows[i].sqPolicyProblem.id, '')
                        // 表单添加校验
                        this.rules[res.rows[i].sqPolicyProblem.id] = [{
                            trigger: 'change', required: true, message: '请选择',
                        }]
                        // 表单添加结构
                        let p = {
                            id: res.rows[i].sqPolicyProblem.id,
                            name: res.rows[i].sqPolicyProblem.name,
                            children: res.rows[i].sqPolicyProblemChildList,
                            correct: '',
                        }
                        p.correct = p.children.find(cv => cv.isCorrect === 'yes').name
                        this.showFrom.push(p)
                    }
                    console.log(this.showFrom)
                    this.$forceUpdate()
                }
            })
        },
    },
    watch: {},
    created() {
        this.getMatchInfo()
    },
    mounted() { },
}
</script>

<style lang="scss" scoped>
.policy-wrap {
    width: 100%;
    font-size: 1rem;
}
/* 匹配结果 */
.policy-res {
    width: 100%;
    height: 100%;
}
/* 匹配结果-头部 */
.policy-res__header {
    margin-bottom: 1.25rem;
    .title {
        background: red;
        color: #fff;
        text-align: center;
        padding: 0.25rem 0;
        margin-bottom: 1.25rem;
    }
    .content-item {
        flex: 4;
        display: -webkit-flex;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid red;
        height: 10rem;
        text-align: center;
    }
}
/* 匹配结果-内容 */
.policy-res__content {
    max-height: 50vh;
    overflow: auto;
    border-bottom: 1px solid #eee;
    .item {
        border-top: 1px solid #eee;
        width: 100%;
        padding: 0.625rem;
    }
    .item-title {
        color: red;
        padding: 0.25rem 0;
    }
    .item-content {
        width: 100%;
        position: relative;
    }
    .item-content__el {
        .el-left,
        .el-right {
            padding: 0.25rem 0.5rem;
        }
        .el-right {
            text-align: center;
        }
    }
    .item-content__btn {
        position: absolute;
        top: 50%;
        right: 0.625rem;
        transform: translateY(-50%);
        background: red;
        padding: 0.125rem 0.875rem;
        border-radius: 3px;
        color: #fff;
        font-size: 14px;
    }
}
.color__blue {
    color: rgb(75, 153, 226);
}
.color__red {
    color: red;
}
</style>